import React from 'react';

import Form from './Form';

import Button from './Button';
import Text from './Text';
import TextList from './TextList';
import TextArea from './TextArea';
import Select from './Select';
import File from './File';
import CheckBox from './CheckBox';
import Radio from './Radio';

import Email from './Email';
import Phone from './Phone';
import CompanyCode from './CompanyCode';
import Address from './Address';
import Rating from './Rating';
import RadioButton from './RadioButton';
import CheckList from './CheckList';
import TrueFalse from './TrueFalse';
import Label from './Label';

import Agreement from './Agreement';
import Member from './Member';

export default Form;

Form.Button = Button;
Form.Text = Text;
Form.TextList = TextList;
Form.TextArea = TextArea;
Form.Select = Select;
Form.File = File;
Form.CheckBox = CheckBox;
Form.Radio = Radio;

Form.Email = Email;
Form.Phone = Phone;
Form.CompanyCode = CompanyCode;
Form.Address = Address;
Form.Rating = Rating;
Form.RadioButton = RadioButton;
Form.CheckList = CheckList;
Form.TrueFalse = TrueFalse;
Form.Label = Label;

Form.Agreement = Agreement;
Form.Member = Member;

Form.Item = ( () => <></> );